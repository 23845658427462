import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { InvoiceSearchRequest, UserDataSources_$reflection, PagedDataResponse$1_$reflection, InvoiceSearchRequest_$reflection, InvoiceListItem_$reflection, User_$reflection, Account_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { class_type, int32_type, bool_type, lambda_type, record_type, list_type, unit_type, union_type, string_type, option_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Currency_formatCurrency, DateTime_formatNullable, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { toInt32, equals, fromInt32, toInt64, compare } from "../fable_modules/fable-library.4.10.0/BigInt.js";
import { safeHash, defaultOf, createObj, equals as equals_1 } from "../fable_modules/fable-library.4.10.0/Util.js";
import { contains, ofArray, filter, cons, singleton, empty } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_items, Alert_info, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportInvoicesAsZip, downloadInvoice, export$, search } from "../Api/InvoiceApi.fs.js";
import { indexed, map as map_1 } from "../fable_modules/fable-library.4.10.0/Array.js";
import { defaultInvoiceSearchRequest } from "../Domain/Invoice.fs.js";
import { createElement } from "react";
import { printf, isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { collect, empty as empty_1, map as map_2, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { maxValue, minValue, toString } from "../fable_modules/fable-library.4.10.0/Date.js";
import { formatDecimalWithUnits, formatInt, toDateTimeOffsetInput } from "../Domain/Formatter.fs.js";
import { value as value_457, toNullable } from "../fable_modules/fable-library.4.10.0/Option.js";
import { DateTimeOffset_fromString } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { fromParts, compare as compare_1 } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(Accounts, User, SelectedInvoice, InvoiceSearchQuery, PreviousInvoiceSearchQuery, InvoicesDeferred, ExportInvoiceMetadataListDeferred, DownloadInvoiceDeferred, InvoicesToZip, ExportInvoicesAsZipDeferred, UserDataSources) {
        super();
        this.Accounts = Accounts;
        this.User = User;
        this.SelectedInvoice = SelectedInvoice;
        this.InvoiceSearchQuery = InvoiceSearchQuery;
        this.PreviousInvoiceSearchQuery = PreviousInvoiceSearchQuery;
        this.InvoicesDeferred = InvoicesDeferred;
        this.ExportInvoiceMetadataListDeferred = ExportInvoiceMetadataListDeferred;
        this.DownloadInvoiceDeferred = DownloadInvoiceDeferred;
        this.InvoicesToZip = InvoicesToZip;
        this.ExportInvoicesAsZipDeferred = ExportInvoicesAsZipDeferred;
        this.UserDataSources = UserDataSources;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Billing.State", [], State, () => [["Accounts", array_type(Account_$reflection())], ["User", User_$reflection()], ["SelectedInvoice", option_type(InvoiceListItem_$reflection())], ["InvoiceSearchQuery", InvoiceSearchRequest_$reflection()], ["PreviousInvoiceSearchQuery", InvoiceSearchRequest_$reflection()], ["InvoicesDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(InvoiceListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(InvoiceListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["ExportInvoiceMetadataListDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["DownloadInvoiceDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["InvoicesToZip", list_type(InvoiceListItem_$reflection())], ["ExportInvoicesAsZipDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["UserDataSources", UserDataSources_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["InvoiceQueryChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Billing.Intent", [], Intent, () => [[["Item", InvoiceSearchRequest_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateInvoiceSearchQuery", "SearchInvoicesAsync", "ExportInvoiceMetadataList", "SetSelectedInvoice", "DownloadInvoice", "AddInvoiceToZipList", "RemoveInvoiceFromZipList", "ExportInvoicesAsZip", "ClearSearch", "SetPageNumber", "ShowShipmentsPopup"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Billing.Msg", [], Msg, () => [[["Item", lambda_type(InvoiceSearchRequest_$reflection(), InvoiceSearchRequest_$reflection())]], [["Item1", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(InvoiceListItem_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(InvoiceListItem_$reflection())]], [["ErrorValue", string_type]]]))], ["Item2", bool_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", InvoiceListItem_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", InvoiceListItem_$reflection()]], [["Item", InvoiceListItem_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [], [["Item", int32_type]], [["Item", class_type("Fable.React.ReactElement")]]]);
}

function hasPreviousPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            return result.fields[0].Page > 1;
        }
        else {
            return false;
        }
    }, state.InvoicesDeferred);
}

function hasNextPage(state) {
    return Deferred_exists((result) => {
        if (result.tag === 0) {
            const pagedData = result.fields[0];
            return compare(toInt64(fromInt32(pagedData.Page * pagedData.PageSize)), pagedData.TotalCount) < 0;
        }
        else {
            return false;
        }
    }, state.InvoicesDeferred);
}

function isExportDisabled(state) {
    if (Deferred_exists((result) => {
        if (result.tag === 0) {
            return equals(result.fields[0].TotalCount, toInt64(fromInt32(0)));
        }
        else {
            return false;
        }
    }, state.InvoicesDeferred)) {
        return true;
    }
    else {
        return equals_1(state.InvoicesDeferred, new Deferred$1(1, []));
    }
}

export function init(accounts, user, invoiceSearchRequest, userDataSources) {
    return [new State(accounts, user, void 0, invoiceSearchRequest, invoiceSearchRequest, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), empty(), new Deferred$1(0, []), userDataSources), singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
    })];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, msg.fields[1] ? state.PreviousInvoiceSearchQuery : state.InvoiceSearchQuery, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Search Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.InvoicesDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, new Deferred$1(1, []), state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, search, msg.fields[1] ? ((bind$0040 = state.PreviousInvoiceSearchQuery, new InvoiceSearchRequest(bind$0040.Page, bind$0040.PageSize, bind$0040.OrderBy, bind$0040.OrderDirection, bind$0040.InvoiceNumber, bind$0040.SelectedInvoiceDate, bind$0040.FromDate, bind$0040.ToDate, map_1((x) => x.Id, state.Accounts), bind$0040.ModeOfTransport, bind$0040.Status))) : ((bind$0040_1 = state.InvoiceSearchQuery, new InvoiceSearchRequest(1, bind$0040_1.PageSize, bind$0040_1.OrderBy, bind$0040_1.OrderDirection, bind$0040_1.InvoiceNumber, bind$0040_1.SelectedInvoiceDate, bind$0040_1.FromDate, bind$0040_1.ToDate, map_1((x_1) => x_1.Id, state.Accounts), bind$0040_1.ModeOfTransport, bind$0040_1.Status))), (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg]), msg.fields[1]]))), new Intent(1, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportInvoiceMetadataListDeferred, new Deferred$1(1, [])) ? true : (state.Accounts.length === 0)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, new Deferred$1(1, []), state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, export$, (bind$0040_2 = state.PreviousInvoiceSearchQuery, new InvoiceSearchRequest(bind$0040_2.Page, bind$0040_2.PageSize, bind$0040_2.OrderBy, bind$0040_2.OrderDirection, bind$0040_2.InvoiceNumber, bind$0040_2.SelectedInvoiceDate, bind$0040_2.FromDate, bind$0040_2.ToDate, map_1((x_2) => x_2.Id, state.Accounts), bind$0040_2.ModeOfTransport, bind$0040_2.Status)), (arg_2) => (new Msg(2, [new AsyncMsg$1(1, [arg_2])]))), new Intent(1, [])];
            }
        case 3:
            return [new State(state.Accounts, state.User, msg.fields[0], state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), singleton((dispatch) => {
                dispatch(new Msg(4, [new AsyncMsg$1(0, [])]));
            }), new Intent(1, [])];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Download Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.DownloadInvoiceDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                const matchValue = state.SelectedInvoice;
                if (matchValue != null) {
                    const selectedInvoice = matchValue;
                    return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, new Deferred$1(1, []), state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), Cmd_OfAsyncWith_perform((computation_2) => {
                        startImmediate(computation_2);
                    }, () => downloadInvoice(selectedInvoice.Id, selectedInvoice.InvoiceNumber, selectedInvoice.FileExtension), void 0, (arg_4) => (new Msg(4, [new AsyncMsg$1(1, [arg_4])]))), new Intent(1, [])];
                }
                else {
                    return [state, Cmd_none(), new Intent(1, [])];
                }
            }
        case 5:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, cons(msg.fields[0], state.InvoicesToZip), state.ExportInvoicesAsZipDeferred, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 6:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, filter((i) => (i.Id !== msg.fields[0].Id), state.InvoicesToZip), state.ExportInvoicesAsZipDeferred, state.UserDataSources), Cmd_none(), new Intent(1, [])];
        case 7:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, new Deferred$1(2, [msg.fields[0].fields[0]]), state.UserDataSources), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Invoices", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.ExportInvoicesAsZipDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (equals_1(state.InvoicesToZip, empty())) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, empty(), new Deferred$1(1, []), state.UserDataSources), Cmd_OfAsyncWith_perform((computation_3) => {
                    startImmediate(computation_3);
                }, exportInvoicesAsZip, state.InvoicesToZip, (arg_6) => (new Msg(7, [new AsyncMsg$1(1, [arg_6])]))), new Intent(1, [])];
            }
        case 8: {
            const newState_1 = new State(state.Accounts, state.User, state.SelectedInvoice, defaultInvoiceSearchRequest, state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources);
            return [newState_1, singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [new AsyncMsg$1(0, []), false]));
            }), new Intent(0, [newState_1.InvoiceSearchQuery])];
        }
        case 9:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, state.InvoiceSearchQuery, (bind$0040_3 = state.PreviousInvoiceSearchQuery, new InvoiceSearchRequest(msg.fields[0], bind$0040_3.PageSize, bind$0040_3.OrderBy, bind$0040_3.OrderDirection, bind$0040_3.InvoiceNumber, bind$0040_3.SelectedInvoiceDate, bind$0040_3.FromDate, bind$0040_3.ToDate, bind$0040_3.AccountIds, bind$0040_3.ModeOfTransport, bind$0040_3.Status)), state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), singleton((dispatch_2) => {
                dispatch_2(new Msg(1, [new AsyncMsg$1(0, []), true]));
            }), new Intent(1, [])];
        case 10:
            return [state, Alert_info("Shipments", Alert_items(msg.fields[0])), new Intent(1, [])];
        default:
            return [new State(state.Accounts, state.User, state.SelectedInvoice, msg.fields[0](state.InvoiceSearchQuery), state.PreviousInvoiceSearchQuery, state.InvoicesDeferred, state.ExportInvoiceMetadataListDeferred, state.DownloadInvoiceDeferred, state.InvoicesToZip, state.ExportInvoicesAsZipDeferred, state.UserDataSources), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_13, elems_1, elems, value_28, elems_4, elems_3, value_47, elems_2, elems_7, elems_6, value_75, elems_5, elems_8, value_106, elems_9, value_130, elems_12, elems_11, value_150, elems_10, elems_26, elems_25, elems_24, elems_14, elems_15, matchValue_2, elems_19, elems_18, elems_17, elems_16, elems_23, elems_40, elems_39, elems_38, elems_27, children, elems_37, elems_44, elems_43;
    const divider = createElement("hr", {
        className: "my-4",
    });
    let searchControls;
    const labelCss = ofArray(["is-block", "mb-1", "has-text-weight-semibold"]);
    const inputCss = ofArray(["input", "is-small", "is-fullwidth"]);
    searchControls = createElement("form", createObj(ofArray([["onSubmit", (e) => {
        e.preventDefault();
        if (state.Accounts.length === 0) {
        }
        else {
            dispatch(new Msg(1, [new AsyncMsg$1(0, []), false]));
        }
    }], ["className", "box"], ["style", {
        minWidth: 300,
    }], (elems_13 = [createElement("div", createObj(ofArray([["className", "block"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "is-align-items-center", "mb-1"])], (elems = [createElement("label", {
        className: "has-text-weight-semibold",
        for: "invoiceNumber",
        children: "Invoice Number",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "invoiceNumber"], ["name", "invoiceNumber"], ["type", "text"], ["maxLength", 50], ["placeholder", "Invoice Number..."], (value_28 = state.InvoiceSearchQuery.InvoiceNumber, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals_1(e_1.value, value_28)) {
            e_1.value = value_28;
        }
    }]), ["onChange", (ev) => {
        const value_30 = ev.target.value;
        dispatch(new Msg(0, [(query) => (new InvoiceSearchRequest(query.Page, query.PageSize, query.OrderBy, query.OrderDirection, value_30, query.SelectedInvoiceDate, query.FromDate, query.ToDate, query.AccountIds, query.ModeOfTransport, query.Status))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_4 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "status",
        children: "Status",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_3 = [createElement("select", createObj(ofArray([["className", ""], ["id", "status"], ["name", "status"], (value_47 = state.InvoiceSearchQuery.Status, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals_1(e_2.value, value_47)) {
            e_2.value = value_47;
        }
    }]), (elems_2 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "All",
    })), delay(() => map_2((item) => createElement("option", {
        value: item.StatusName,
        children: item.StatusName,
    }), state.UserDataSources.InvoiceStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]), ["onChange", (e_3) => {
        const element = e_3.target;
        dispatch(new Msg(0, [(query_1) => (new InvoiceSearchRequest(query_1.Page, query_1.PageSize, query_1.OrderBy, query_1.OrderDirection, query_1.InvoiceNumber, query_1.SelectedInvoiceDate, query_1.FromDate, query_1.ToDate, query_1.AccountIds, query_1.ModeOfTransport, element.value))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_7 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "shipmentDate",
        children: "Date",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_6 = [createElement("select", createObj(ofArray([["className", ""], ["id", "shipmentDate"], ["name", "shipmentDate"], (value_75 = state.InvoiceSearchQuery.SelectedInvoiceDate, ["ref", (e_4) => {
        if (!(e_4 == null) && !equals_1(e_4.value, value_75)) {
            e_4.value = value_75;
        }
    }]), (elems_5 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "",
        children: "Select...",
    })), delay(() => map_2((item_1) => createElement("option", {
        value: item_1.InvoiceDate,
        children: item_1.InvoiceDate,
    }), state.UserDataSources.InvoiceDates))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]), ["onChange", (e_5) => {
        const element_1 = e_5.target;
        dispatch(new Msg(0, [(query_2) => (isNullOrWhiteSpace(element_1.value) ? (new InvoiceSearchRequest(query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection, query_2.InvoiceNumber, element_1.value, defaultOf(), defaultOf(), query_2.AccountIds, query_2.ModeOfTransport, query_2.Status)) : (new InvoiceSearchRequest(query_2.Page, query_2.PageSize, query_2.OrderBy, query_2.OrderDirection, query_2.InvoiceNumber, element_1.value, query_2.FromDate, query_2.ToDate, query_2.AccountIds, query_2.ModeOfTransport, query_2.Status)))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_8 = [createElement("label", {
        className: join(" ", labelCss),
        for: "fromDate",
        children: "From",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "fromDate"], ["name", "fromDate"], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_106 = toDateTimeOffsetInput(state.InvoiceSearchQuery.FromDate), ["ref", (e_6) => {
        if (!(e_6 == null) && !equals_1(e_6.value, value_106)) {
            e_6.value = value_106;
        }
    }]), ["onChange", (e_7) => {
        const element_2 = e_7.target;
        dispatch(new Msg(0, [(query_3) => (new InvoiceSearchRequest(query_3.Page, query_3.PageSize, query_3.OrderBy, query_3.OrderDirection, query_3.InvoiceNumber, query_3.SelectedInvoiceDate, isNullOrWhiteSpace(element_2.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_2.value)), query_3.ToDate, query_3.AccountIds, query_3.ModeOfTransport, query_3.Status))]));
    }], ["disabled", isNullOrWhiteSpace(state.InvoiceSearchQuery.SelectedInvoiceDate)]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_9 = [createElement("label", {
        className: join(" ", labelCss),
        for: "toDate",
        children: "To",
    }), createElement("input", createObj(ofArray([["className", join(" ", inputCss)], ["id", "toDate"], ["name", "toDate"], ["type", "date"], ["min", toString(minValue(), "yyyy-MM-dd")], ["max", toString(maxValue(), "yyyy-MM-dd")], (value_130 = toDateTimeOffsetInput(state.InvoiceSearchQuery.ToDate), ["ref", (e_8) => {
        if (!(e_8 == null) && !equals_1(e_8.value, value_130)) {
            e_8.value = value_130;
        }
    }]), ["onChange", (e_9) => {
        const element_3 = e_9.target;
        dispatch(new Msg(0, [(query_4) => (new InvoiceSearchRequest(query_4.Page, query_4.PageSize, query_4.OrderBy, query_4.OrderDirection, query_4.InvoiceNumber, query_4.SelectedInvoiceDate, query_4.FromDate, isNullOrWhiteSpace(element_3.value) ? defaultOf() : toNullable(DateTimeOffset_fromString(element_3.value)), query_4.AccountIds, query_4.ModeOfTransport, query_4.Status))]));
    }], ["disabled", isNullOrWhiteSpace(state.InvoiceSearchQuery.SelectedInvoiceDate)]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "block"], (elems_12 = [createElement("label", {
        className: join(" ", ["is-block", "mb-1", "has-text-weight-semibold"]),
        for: "mot",
        children: "Mode Of Transport",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_11 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-fullwidth"])], ["id", "mot"], ["name", "mot"], (value_150 = state.InvoiceSearchQuery.ModeOfTransport, ["ref", (e_10) => {
        if (!(e_10 == null) && !equals_1(e_10.value, value_150)) {
            e_10.value = value_150;
        }
    }]), (elems_10 = toList(delay(() => append(singleton_1(createElement("option", {
        value: "Any",
        children: "Any",
    })), delay(() => map_2((mot) => createElement("option", {
        value: mot.ModeOfTransport,
        children: mot.ModeOfTransport,
    }), state.UserDataSources.ModesOfTransport))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))]), ["onChange", (e_11) => {
        const element_4 = e_11.target;
        dispatch(new Msg(0, [(query_5) => (new InvoiceSearchRequest(query_5.Page, query_5.PageSize, query_5.OrderBy, query_5.OrderDirection, query_5.InvoiceNumber, query_5.SelectedInvoiceDate, query_5.FromDate, query_5.ToDate, query_5.AccountIds, element_4.value, query_5.Status))]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), divider, createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.InvoicesDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))))))))))),
        type: "submit",
        children: "Search",
        disabled: state.Accounts.length === 0,
    }), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton_1("block"), delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals_1(state.InvoicesDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))))))))),
        type: "button",
        onClick: (_arg) => {
            dispatch(new Msg(8, []));
        },
        children: "Clear",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])));
    const header = createElement("div", createObj(ofArray([["className", "block"], (elems_26 = [createElement("div", createObj(ofArray([["className", "box"], (elems_25 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-align-items-center"])], (elems_24 = [createElement("div", createObj(ofArray([["className", "column"], (elems_14 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link", "is-size-5"]),
        children: "Billing",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_15 = [createElement("div", {
        className: "has-text-weight-semibold",
        children: (matchValue_2 = state.InvoicesDeferred, (matchValue_2.tag === 1) ? "Searching..." : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? ((compare(matchValue_2.fields[0].fields[0].TotalCount, toInt64(fromInt32(0))) > 0) ? (`${formatInt(~~toInt32(matchValue_2.fields[0].fields[0].TotalCount))} invoices found | showing page ${formatInt(matchValue_2.fields[0].fields[0].Page)} of ${formatInt(matchValue_2.fields[0].fields[0].NumberOfPages)}`) : "0 invoices found") : "") : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow", "is-hidden"])], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-items-center"])], (elems_18 = [createElement("div", {
        className: join(" ", ["has-text-weight-semibold", "mr-2"]),
        children: "Sort By ",
    }), createElement("div", createObj(ofArray([["className", "select"], (elems_17 = [createElement("select", createObj(ofArray([["className", join(" ", ["is-small", "is-fullwidth"])], (elems_16 = [createElement("option", {
        value: true,
        children: "Due Date",
    }), createElement("option", {
        value: true,
        children: "Issue Date",
    }), createElement("option", {
        value: true,
        children: "Status",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "column is-narrow"], (elems_23 = toList(delay(() => {
        let elems_20;
        return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state)], ["onClick", (_arg_1) => {
            if (hasPreviousPage(state)) {
                dispatch(new Msg(9, [state.PreviousInvoiceSearchQuery.Page - 1]));
            }
        }], (elems_20 = [createElement("span", {
            className: join(" ", ["material-icons"]),
            children: "navigate_before",
        }), createElement("span", {
            className: join(" ", ["mx-1"]),
        }), createElement("span", {
            children: "Previous",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))), delay(() => {
            let elems_21;
            return append(singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "mr-2"])], ["type", "button"], ["disabled", !hasNextPage(state)], ["onClick", (_arg_2) => {
                if (hasNextPage(state)) {
                    dispatch(new Msg(9, [state.PreviousInvoiceSearchQuery.Page + 1]));
                }
            }], (elems_21 = [createElement("span", {
                className: join(" ", ["material-icons"]),
                children: "navigate_next",
            }), createElement("span", {
                className: join(" ", ["mx-1"]),
            }), createElement("span", {
                children: "Next",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))), delay(() => append(state.User.CanExportBilling ? singleton_1(createElement("button", {
                className: join(" ", ["button", "is-small", "mr-2", equals_1(state.InvoicesToZip, empty()) ? "is-hidden" : ""]),
                hidden: true,
                type: "button",
                children: (state.ExportInvoicesAsZipDeferred.tag === 1) ? "Creating zip..." : "Download zip archive",
                disabled: equals_1(state.ExportInvoicesAsZipDeferred, new Deferred$1(1, [])),
                onClick: (_arg_3) => {
                    dispatch(new Msg(7, [new AsyncMsg$1(0, [])]));
                },
            })) : empty_1(), delay(() => {
                let elems_22;
                return state.User.CanExportBilling ? singleton_1(createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", equals_1(state.ExportInvoiceMetadataListDeferred, new Deferred$1(1, [])) ? true : isExportDisabled(state)], ["onClick", (_arg_4) => {
                    dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                }], (elems_22 = [createElement("span", {
                    className: join(" ", ["material-icons"]),
                    children: "download",
                }), createElement("span", {
                    className: join(" ", ["mx-1"]),
                }), createElement("span", {
                    children: (state.ExportInvoiceMetadataListDeferred.tag === 1) ? "Exporting..." : "Export",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))) : empty_1();
            }))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])));
    let searchResult;
    const matchValue_5 = state.InvoicesDeferred;
    searchResult = ((matchValue_5.tag === 1) ? render_1() : ((matchValue_5.tag === 2) ? ((matchValue_5.fields[0].tag === 0) ? createElement("div", createObj(ofArray([["className", "box"], (elems_40 = [createElement("div", createObj(ofArray([["className", "table-container"], (elems_39 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_38 = [createElement("thead", createObj(singleton((elems_27 = [(children = ofArray([createElement("th", {
        className: "p-2",
        scope: "col",
        children: "",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Invoice Number",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Shipment",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Issue Date",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Due Date",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Paid Date",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Terms",
    }), createElement("th", {
        className: "p-2",
        scope: "col",
        children: "Status",
    }), createElement("th", {
        className: join(" ", ["p-2", "has-text-right"]),
        scope: "col",
        children: "Amount USD",
    }), createElement("th", {
        className: join(" ", ["p-2", "has-text-right"]),
        scope: "col",
        children: "Local Amount",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])))), createElement("tbody", createObj(singleton((elems_37 = toList(delay(() => collect((invoice) => {
        let children_3, elems_28, elems_29, elems_35, elems_36;
        const fileNotFound = !invoice.FileExists;
        const amountUsd = formatDecimalWithUnits("", invoice.AmountUsd);
        return singleton_1((children_3 = ofArray([createElement("td", createObj(ofArray([["className", "p-2"], (elems_28 = toList(delay(() => (fileNotFound ? singleton_1(createElement("p", {
            children: "",
        })) : singleton_1(createElement("input", {
            id: invoice.Id,
            name: invoice.InvoiceNumber,
            type: "checkbox",
            checked: contains(invoice, state.InvoicesToZip, {
                Equals: equals_1,
                GetHashCode: safeHash,
            }),
            onChange: (ev_1) => {
                const value_354 = ev_1.target.checked;
                if (value_354) {
                    dispatch(new Msg(5, [invoice]));
                }
                else {
                    dispatch(new Msg(6, [invoice]));
                }
            },
        }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_29 = toList(delay(() => {
            let fmt;
            return fileNotFound ? singleton_1(createElement("p", {
                children: invoice.InvoiceNumber,
            })) : singleton_1(createElement("a", createObj(ofArray([["className", join(" ", ["is-link", "is-underlined"])], ["style", {
                overflowWrap: "anywhere",
            }], ["onClick", (_arg_5) => {
                dispatch(new Msg(3, [invoice]));
            }], ((fmt = printf("%s%s"), fmt.cont((value_365) => ["children", value_365])))(invoice.InvoiceNumber)(invoice.FileExtension)]))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_35 = toList(delay(() => {
            if (invoice.Shipments.length === 1) {
                return map_2((shipment) => {
                    let elems_31, elems_30;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_31 = [ModeOfTransportIcon_render(new IconType(0, []), shipment.ModeOfTransportIcon), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_30 = [createElement("a", {
                        className: "is-link",
                        href: RouterModule_encodeParts(ofArray(["/shipment-details", (shipment.ShipmentId != null) ? value_457(shipment.ShipmentId) : ""]), 1),
                        children: shipment.ShipmentNumber,
                    }), createElement("div", {
                        children: [shipment.ServiceType],
                    }), createElement("div", {
                        children: [shipment.ServiceLevel],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])));
                }, invoice.Shipments);
            }
            else {
                const children_2 = map_1((shipment_1) => {
                    let elems_33, elems_32;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_33 = [ModeOfTransportIcon_render(new IconType(0, []), shipment_1.ModeOfTransportIcon), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2", "is-align-items-flex-start"])], ["style", {
                        width: 200 + "px",
                    }], (elems_32 = [createElement("a", {
                        className: "is-link",
                        href: RouterModule_encodeParts(ofArray(["/shipment-details", (shipment_1.ShipmentId != null) ? value_457(shipment_1.ShipmentId) : ""]), 1),
                        children: shipment_1.ShipmentNumber,
                    }), createElement("div", {
                        children: [shipment_1.ServiceType],
                    }), createElement("div", {
                        children: [shipment_1.ServiceLevel],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
                }, invoice.Shipments);
                return singleton_1(createElement("button", {
                    type: "button",
                    className: join(" ", ["button", "is-small"]),
                    children: "Multiple Shipments",
                    onClick: (_arg_6) => {
                        dispatch(new Msg(10, [createElement("div", {
                            className: join(" ", ["is-flex", "is-flex-direction-column", "is-align-items-center"]),
                            style: {
                                maxHeight: 450 + "px",
                                overflow: "auto",
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                        })]));
                    },
                }));
            }
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])]))), createElement("td", {
            className: "p-2",
            children: DateTime_formatNullable(invoice.IssuedDate),
        }), createElement("td", {
            className: "p-2",
            children: DateTime_formatNullable(invoice.DueDate),
        }), createElement("td", {
            className: "p-2",
            children: DateTime_formatNullable(invoice.PaidDate),
        }), createElement("td", {
            className: "p-2",
            children: invoice.PaymentTerms,
        }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_36 = toList(delay(() => collect((matchValue_8) => append(singleton_1(createElement("span", {
            className: join(" ", ["tag", invoice.StatusClass]),
            children: matchValue_8[1],
        })), delay(() => ((matchValue_8[0] === 0) ? singleton_1(" ") : empty_1()))), indexed(invoice.Statuses)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])]))), createElement("td", {
            className: join(" ", toList(delay(() => append(singleton_1("p-2"), delay(() => append(singleton_1("has-text-right"), delay(() => ((invoice.AmountUsd != null) ? ((compare_1(value_457(invoice.AmountUsd), fromParts(0, 0, 0, false, 0)) < 0) ? singleton_1("has-text-danger") : empty_1()) : empty_1())))))))),
            children: Currency_formatCurrency("en-US", "USD", invoice.AmountUsd),
        }), createElement("td", {
            className: join(" ", toList(delay(() => append(singleton_1("p-2"), delay(() => append(singleton_1("has-text-right"), delay(() => ((invoice.LocalAmount != null) ? ((compare_1(value_457(invoice.LocalAmount), fromParts(0, 0, 0, false, 0)) < 0) ? singleton_1("has-text-danger") : empty_1()) : empty_1())))))))),
            children: Currency_formatCurrency("en-US", invoice.Currency, invoice.LocalAmount),
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
        })));
    }, matchValue_5.fields[0].fields[0].Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])]))) : defaultOf_1()) : defaultOf_1()));
    if (state.Accounts.length === 0) {
        const children_5 = singleton(createElement("h4", {
            className: join(" ", ["has-text-weight-semibold", "has-text-centered", "is-size-5"]),
            children: "Please select a client account",
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_5)),
        });
    }
    else {
        return createElement("div", createObj(singleton((elems_44 = [header, createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_43 = [createElement("div", {
            className: join(" ", ["column", "is-narrow"]),
            children: Interop_reactApi.Children.toArray([searchControls]),
        }), createElement("div", {
            className: join(" ", ["column"]),
            children: Interop_reactApi.Children.toArray([searchResult]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))]))));
    }
}

