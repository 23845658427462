import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { ofArray, filter } from "../fable_modules/fable-library.4.10.0/List.js";
import { String_encode } from "../Extensions.fs.js";
import { Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_send } from "../fable_modules/Fable.SimpleHttp.3.5.0/Http.fs.js";
import { HttpMethod } from "../fable_modules/Fable.SimpleHttp.3.5.0/Types.fs.js";
import { getIdToken } from "../Authentication.fs.js";
import { FSharpResult$2, Result_MapError } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { SimpleJson_parseNative, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { GlobalAccountAnalyticsReport_$reflection, GlobalAccountModules_$reflection, GlobalAccount_$reflection, PagedDataResponse$1_$reflection, AccountGroup_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";
import { list_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";

export function searchGlobalAccounts(searchCriteria, onlyGlobalAccounts, onlyShowActive, page, orderBy, orderDirection) {
    return singleton.Delay(() => {
        let req_2, req_1;
        const queryParams = join("&", filter((arg) => !isNullOrWhiteSpace(arg), ofArray([(searchCriteria != null) ? (`searchEncoded=${String_encode(searchCriteria)}`) : "", `onlyGlobalAccounts=${onlyGlobalAccounts}`, `onlyShowActive=${onlyShowActive}`, `page=${page}`, `orderBy=${orderBy}`, `orderDirection=${orderDirection}`])));
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/AccountGroups?${queryParams}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(AccountGroup_$reflection())))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

export function getGlobalAccount(globalAccountId) {
    return singleton.Delay(() => {
        let req_2, req_1;
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/AccountGroups/${globalAccountId}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(GlobalAccount_$reflection()))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

export function getGlobalAccountModules(globalAccountId) {
    return singleton.Delay(() => {
        let req_2, req_1;
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/GlobalAccountModules/${globalAccountId}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(GlobalAccountModules_$reflection()))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

export function getGlobalAccountAnalyticReports(globalAccountId) {
    return singleton.Delay(() => {
        let req_2, req_1;
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/GlobalAccount/Analytics/${globalAccountId}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(list_type(GlobalAccountAnalyticsReport_$reflection())))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

