import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { DateTime_formatNullable } from "../Extensions.fs.js";
import { formatDecimalWithUnits } from "../Domain/Formatter.fs.js";

export function render(shipment) {
    let elems_25, elems_6, elems_1, elems, elems_3, elems_2, elems_5, elems_4, elems_14, elems_9, elems_8, elems_7, elems_11, elems_10, elems_13, elems_12, elems_19, elems_16, elems_15, elems_18, elems_17, elems_24, elems_21, elems_20, elems_23, elems_22;
    return createElement("div", createObj(ofArray([["className", join(" ", ["tab", "mx-4"])], ["id", "details"], ["style", {
        minHeight: 300 + "px",
        display: "block",
    }], (elems_25 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems = toList(delay(() => append(singleton(createElement("span", {
        className: "has-text-weight-semibold",
        children: "Client",
    })), delay(() => append(singleton(createElement("span", {
        children: [shipment.CustomerName],
    })), delay(() => map((customerAddress) => createElement("span", {
        children: [customerAddress],
    }), shipment.CustomerAddress))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_2 = toList(delay(() => append(singleton(createElement("span", {
        className: "has-text-weight-semibold",
        children: "Shipper",
    })), delay(() => append(singleton(createElement("span", {
        children: [shipment.ShipperName],
    })), delay(() => map((shipperAddress) => createElement("span", {
        children: [shipperAddress],
    }), shipment.ShipperAddress))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_4 = toList(delay(() => append(singleton(createElement("span", {
        className: "has-text-weight-semibold",
        children: "Consignee",
    })), delay(() => append(singleton(createElement("span", {
        children: [shipment.ConsigneeName],
    })), delay(() => map((consigneeAddress) => createElement("span", {
        children: [consigneeAddress],
    }), shipment.ConsigneeAddress))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_14 = [createElement("div", createObj(ofArray([["className", "column"], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_8 = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "mr-2"]),
        children: "Booking",
    }), createElement("span", {
        children: [shipment.BookingNumber],
    }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_7 = [createElement("span", {
        children: [DateTime_formatNullable(shipment.BookingDate)],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_10 = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "mr-2"]),
        children: "Origin",
    }), createElement("span", {
        children: [shipment.Origin],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_12 = [createElement("span", {
        className: join(" ", ["has-text-weight-semibold", "mr-2"]),
        children: "Destination",
    }), createElement("span", {
        children: [shipment.Destination],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_19 = [createElement("div", createObj(ofArray([["className", "column"], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_15 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "MBL #",
    }), createElement("span", {
        children: [shipment.ConsolidationNumber],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_17 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "BL #",
    }), createElement("span", {
        children: [shipment.ShipmentNumber],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", {
        className: "column",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_24 = [createElement("div", createObj(ofArray([["className", "column"], (elems_21 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_20 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "Gross Weight",
    }), createElement("span", {
        children: [formatDecimalWithUnits(shipment.TotalWeightUnit, shipment.TotalWeight)],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_23 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_22 = [createElement("span", {
        className: "has-text-weight-semibold",
        children: "Chargeable Weight",
    }), createElement("span", {
        children: [formatDecimalWithUnits(shipment.TotalChargeableWeightUnit, shipment.TotalChargeableWeight)],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("div", {
        className: "column",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])));
}

