import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { NewFavoritedPackingList, User, FavoritedPackingList, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, unit_type, union_type, array_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { PackingListFilterDataSources, filterPackingList, defaultPackingListFilterDataSources, defaultPackingList, PackingListFilterDataSources_$reflection } from "../Domain/Inventory.fs.js";
import { PackinglistMaterialReceiptItemExportRequest, GlobalAccountTotalCountResponse_$reflection, PackingList_$reflection, PackingListLowBox_$reflection } from "../../CView.DTOs/Inventory.fs.js";
import { DateTime_formatNullable, LocalStorage_getGlobalAccount, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray as ofArray_1, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { sortBy, tryHead, map, collect, choose, append } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { createObj, comparePrimitives, stringHash, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { deleteFavoritePackingList, createFavoritePackingList } from "../Api/UserApi.fs.js";
import { String_defaultValue } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { Array_distinct } from "../fable_modules/fable-library.4.10.0/Seq2.js";
import { defaultOf } from "../fable_modules/FsToolkit.ErrorHandling.4.18.0/../../Pages/../fable_modules/fable-library.4.10.0/Util.js";
import { ofArray, toArray } from "../fable_modules/fable-library.4.10.0/Set.js";
import { findPackingList, exportPackingListDetails } from "../Api/InventoryApi.fs.js";
import { defaultOf as defaultOf_1 } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { value as value_315, defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { collect as collect_1, empty, map as map_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { formatInt, formatDecimalWithUnits } from "../Domain/Formatter.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";

export class State extends Record {
    constructor(User, PackingListId, SelectedPONumber, SelectedVendor, SelectedBoxNumber, SelectedMRNumber, FilterDataSource, FilteredPackingList, PackingListDeferred, ExportPackingListDetailsDeferred, FollowPackingList, UnFollowPackingList) {
        super();
        this.User = User;
        this.PackingListId = PackingListId;
        this.SelectedPONumber = SelectedPONumber;
        this.SelectedVendor = SelectedVendor;
        this.SelectedBoxNumber = SelectedBoxNumber;
        this.SelectedMRNumber = SelectedMRNumber;
        this.FilterDataSource = FilterDataSource;
        this.FilteredPackingList = FilteredPackingList;
        this.PackingListDeferred = PackingListDeferred;
        this.ExportPackingListDetailsDeferred = ExportPackingListDetailsDeferred;
        this.FollowPackingList = FollowPackingList;
        this.UnFollowPackingList = UnFollowPackingList;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.PackingListDetails.State", [], State, () => [["User", User_$reflection()], ["PackingListId", class_type("System.Guid")], ["SelectedPONumber", string_type], ["SelectedVendor", string_type], ["SelectedBoxNumber", string_type], ["SelectedMRNumber", string_type], ["FilterDataSource", PackingListFilterDataSources_$reflection()], ["FilteredPackingList", Deferred$1_$reflection(array_type(PackingListLowBox_$reflection()))], ["PackingListDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PackingList_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", PackingList_$reflection()]], [["ErrorValue", string_type]]]))], ["ExportPackingListDetailsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FollowPackingList", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["UnFollowPackingList", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "PackingListFollowed", "PackingListUnfollowed", "Search", "AccountHierarchyItem"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.PackingListDetails.Intent", [], Intent, () => [[], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [], [["Item", GlobalAccountTotalCountResponse_$reflection()]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetPackingList", "FollowPackingList", "UnfollowPackingList", "SetSelectedPONumber", "SetSelectedVendor", "SetSelectedBoxNumber", "SetSelectedMRNumber", "SetFilteredDataSource", "ExportPackingListDetailsAsync", "BreadcrumbClicked"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.PackingListDetails.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PackingList_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", PackingList_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", PackingList_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], []]);
}

function toPackingList(state) {
    const matchValue = state.PackingListDeferred;
    switch (matchValue.tag) {
        case 1:
            return defaultPackingList;
        case 2:
            if (matchValue.fields[0].tag === 0) {
                return matchValue.fields[0].fields[0];
            }
            else {
                return defaultPackingList;
            }
        default:
            return defaultPackingList;
    }
}

export function init(packingListId, user) {
    return [new State(user, packingListId, "All", "All", "All", "All", defaultPackingListFilterDataSources, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, [])), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })))];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                const packingList_1 = toPackingList(state);
                return [new State((msg.fields[0].fields[0].tag === 1) ? state.User : ((bind$0040 = state.User, new User(bind$0040.Id, bind$0040.UserTypeId, bind$0040.Email, bind$0040.DisplayName, bind$0040.IsActive, bind$0040.CanViewAirShipments, bind$0040.CanViewOceanShipments, bind$0040.CanViewGroundShipments, bind$0040.CanCreateBookings, bind$0040.CanViewBookings, bind$0040.CanExportSearchResults, bind$0040.CanViewInventory, bind$0040.CanViewAnalytics, bind$0040.CanViewRailShipments, bind$0040.CanViewCO2Emissions, bind$0040.CanViewBilling, bind$0040.CanViewShipments, bind$0040.CanExportBookings, bind$0040.CanExportInventory, bind$0040.CanExportBilling, bind$0040.CanExportDocuments, bind$0040.GlobalAccountId, bind$0040.GlobalAccountName, bind$0040.Accounts, bind$0040.Shipments, bind$0040.PurchaseOrders, bind$0040.MaterialReceipts, append([new FavoritedPackingList(packingList_1.Id, packingList_1.PackingListNumber)], state.User.PackingLists), bind$0040.UserPreferences))), state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.UnFollowPackingList), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Pin Packing List", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("Pin Packing List", Toast_text("You have successfully pinned the packing list to the dashboard"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(1, [packingList_1.Id, packingList_1.PackingListNumber]))];
            }
            else if (equals(state.FollowPackingList, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, new Deferred$1(1, []), state.UnFollowPackingList), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, createFavoritePackingList, new NewFavoritedPackingList(state.PackingListId), (arg_2) => (new Msg(1, [new AsyncMsg$1(1, [arg_2])]))), new Intent(0, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                const packingList_2 = toPackingList(state);
                return [new State((msg.fields[0].fields[0].tag === 1) ? state.User : ((bind$0040_1 = state.User, new User(bind$0040_1.Id, bind$0040_1.UserTypeId, bind$0040_1.Email, bind$0040_1.DisplayName, bind$0040_1.IsActive, bind$0040_1.CanViewAirShipments, bind$0040_1.CanViewOceanShipments, bind$0040_1.CanViewGroundShipments, bind$0040_1.CanCreateBookings, bind$0040_1.CanViewBookings, bind$0040_1.CanExportSearchResults, bind$0040_1.CanViewInventory, bind$0040_1.CanViewAnalytics, bind$0040_1.CanViewRailShipments, bind$0040_1.CanViewCO2Emissions, bind$0040_1.CanViewBilling, bind$0040_1.CanViewShipments, bind$0040_1.CanExportBookings, bind$0040_1.CanExportInventory, bind$0040_1.CanExportBilling, bind$0040_1.CanExportDocuments, bind$0040_1.GlobalAccountId, bind$0040_1.GlobalAccountName, bind$0040_1.Accounts, bind$0040_1.Shipments, bind$0040_1.PurchaseOrders, bind$0040_1.MaterialReceipts, state.User.PackingLists.filter((userPackingList) => (userPackingList.PackingListId !== packingList_2.Id)), bind$0040_1.UserPreferences))), state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Unpin Packing List", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("Unpin Packing List", Toast_text("You have successfully unpinned the packing list from the dashboard"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(2, [packingList_2.Id, packingList_2.PackingListNumber]))];
            }
            else if (equals(state.UnFollowPackingList, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_2) => {
                    startImmediate(computation_2);
                }, deleteFavoritePackingList, state.PackingListId, (arg_4) => (new Msg(2, [new AsyncMsg$1(1, [arg_4])]))), new Intent(0, [])];
            }
        case 3:
            return [new State(state.User, state.PackingListId, msg.fields[0], state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), Cmd_none(), new Intent(0, [])];
        case 5:
            return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, msg.fields[0], state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), Cmd_none(), new Intent(0, [])];
        case 6:
            return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, msg.fields[0], state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), Cmd_none(), new Intent(0, [])];
        case 4:
            return [new State(state.User, state.PackingListId, state.SelectedPONumber, (msg.fields[0] === "") ? String_defaultValue() : msg.fields[0], state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), Cmd_none(), new Intent(0, [])];
        case 7: {
            const filteredLowBox = filterPackingList(msg.fields[0].Boxes, state.SelectedBoxNumber, state.SelectedPONumber, state.SelectedMRNumber, state.SelectedVendor);
            const uniqueBoxeNumbers = Array_distinct(choose((item) => {
                const value_6 = item.BoxNumber;
                if (value_6 === defaultOf()) {
                    return void 0;
                }
                else {
                    return value_6;
                }
            }, filteredLowBox), {
                Equals: (x_2, y) => (x_2 === y),
                GetHashCode: stringHash,
            });
            const mapMaterialReceipts = collect((item_2) => item_2.MaterialReceiptItems, collect((item_1) => item_1.MaterialReceipts, filteredLowBox));
            const uniquePurchaseOrderNumbers = Array_distinct(choose((x_3) => {
                const value_8 = x_3.PurchaseOrderNumber;
                if (value_8 === defaultOf()) {
                    return void 0;
                }
                else {
                    return value_8;
                }
            }, mapMaterialReceipts), {
                Equals: (x_4, y_1) => (x_4 === y_1),
                GetHashCode: stringHash,
            });
            const uniqueMaterialReceiptsNumbers = Array_distinct(choose((x_5) => {
                const value_10 = x_5.MaterialReceiptNumber;
                if (value_10 === defaultOf()) {
                    return void 0;
                }
                else {
                    return value_10;
                }
            }, mapMaterialReceipts), {
                Equals: (x_6, y_2) => (x_6 === y_2),
                GetHashCode: stringHash,
            });
            return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, new PackingListFilterDataSources(toArray(ofArray(map((item_3) => item_3.VendorName, mapMaterialReceipts), {
                Compare: comparePrimitives,
            })), uniqueBoxeNumbers, uniquePurchaseOrderNumbers, uniqueMaterialReceiptsNumbers), new Deferred$1(2, [filteredLowBox]), state.PackingListDeferred, state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), Cmd_none(), new Intent(0, [])];
        }
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.FollowPackingList, state.UnFollowPackingList), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Export Packing List Details", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(0, [])];
            }
            else if (equals(state.ExportPackingListDetailsDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, state.PackingListDeferred, new Deferred$1(1, []), state.FollowPackingList, state.UnFollowPackingList), Cmd_OfAsyncWith_perform((computation_3) => {
                    startImmediate(computation_3);
                }, exportPackingListDetails, new PackinglistMaterialReceiptItemExportRequest(state.PackingListId), (arg_6) => (new Msg(8, [new AsyncMsg$1(1, [arg_6])]))), new Intent(0, [])];
            }
        case 9:
            return [state, Cmd_none(), new Intent(3, [])];
        default:
            if (msg.fields[0].tag === 1) {
                let patternInput;
                if (msg.fields[0].fields[0].tag === 1) {
                    patternInput = [Alert_error(Alert_title("Packing List", Alert_text(msg.fields[0].fields[0].fields[0]))), new Intent(0, [])];
                }
                else {
                    const packingList = msg.fields[0].fields[0].fields[0];
                    patternInput = [singleton((dispatch) => {
                        dispatch(new Msg(7, [packingList]));
                    }), (LocalStorage_getGlobalAccount() != null) ? (new Intent(0, [])) : (new Intent(4, [packingList.GlobalAccountTotalCount]))];
                }
                return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, state.FilteredPackingList, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), patternInput[0], patternInput[1]];
            }
            else if (equals(state.PackingListDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.PackingListId, state.SelectedPONumber, state.SelectedVendor, state.SelectedBoxNumber, state.SelectedMRNumber, state.FilterDataSource, new Deferred$1(1, []), new Deferred$1(1, []), state.ExportPackingListDetailsDeferred, state.FollowPackingList, state.UnFollowPackingList), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, findPackingList, state.PackingListId, (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
            }
    }
}

export function render(state, dispatch) {
    let input_5, input_3, input_1, elems_45, elems_4, elems_2, elems, elems_1, elems_3, elems_13, elems_12, elems_5, elems_6, elems_7, elems_8, elems_9, elems_11, elems_10, children, elems_44, elems_34, elems_33, elems_32, elems_43;
    const matchValue = state.PackingListDeferred;
    let matchResult, packingList;
    switch (matchValue.tag) {
        case 2: {
            if (matchValue.fields[0].tag === 0) {
                matchResult = 2;
                packingList = matchValue.fields[0].fields[0];
            }
            else {
                matchResult = 0;
            }
            break;
        }
        case 1: {
            matchResult = 1;
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return defaultOf_1();
        case 1:
            return render_1();
        default: {
            const isFavoritePackingList = state.User.PackingLists.some((s) => (s.PackingListId === state.PackingListId));
            const vendorName = defaultArg((input_5 = ((input_3 = ((input_1 = tryHead(packingList.Boxes), (input_1 == null) ? void 0 : tryHead(input_1.MaterialReceipts))), (input_3 == null) ? void 0 : tryHead(input_3.MaterialReceiptItems))), (input_5 == null) ? void 0 : input_5.VendorName), "");
            return createElement("div", createObj(singleton((elems_45 = [createElement("nav", createObj(ofArray_1([["aria-label", "breadcrumbs"], ["className", join(" ", ["breadcrumb", "is-small", "box", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between", "is-align-items-center"])], (elems_4 = [createElement("ul", createObj(ofArray_1([["className", join(" ", ["has-text-weight-semibold", "is-align-items-center"])], (elems_2 = [createElement("li", createObj(singleton((elems = [createElement("a", {
                className: "is-size-6",
                href: RouterModule_encodeParts(singleton("/inventory"), 1),
                onClick: (_arg) => {
                    dispatch(new Msg(9, []));
                },
                children: "Packing List",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("li", createObj(ofArray_1([["className", join(" ", ["is-active", "is-size-6"])], (elems_1 = [createElement("a", {
                children: packingList.PackingListNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray_1([["className", join(" ", ["is-flex", "is-align-content-center"])], (elems_3 = [createElement("i", {
                className: join(" ", toList(delay(() => append_1(singleton_1("material-icons"), delay(() => append_1(singleton_1("mot-icon"), delay(() => append_1(singleton_1("m1-3"), delay(() => append_1(singleton_1("is-clickable"), delay(() => (isFavoritePackingList ? singleton_1("has-text-link-dark") : singleton_1("has-text-grey-light"))))))))))))),
                title: "Favorite",
                children: "push_pin",
                onClick: (_arg_1) => {
                    if (isFavoritePackingList) {
                        dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                    }
                    else {
                        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
                    }
                },
                disabled: equals(state.FollowPackingList, new Deferred$1(1, [])) ? true : equals(state.UnFollowPackingList, new Deferred$1(1, [])),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray_1([["className", "box"], (elems_13 = [createElement("div", createObj(ofArray_1([["className", "columns is-multiline "], (elems_12 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["column"])], (elems_5 = [createElement("p", {
                children: "Ship Date",
            }), createElement("span", {
                className: join(" ", ["is-size-6 has-text-weight-bold"]),
                children: DateTime_formatNullable(packingList.ShipDate),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray_1([["className", join(" ", ["column"])], (elems_6 = [createElement("p", {
                children: "Imperial Volume",
            }), createElement("span", {
                className: join(" ", ["is-size-6 has-text-weight-bold"]),
                children: formatDecimalWithUnits(packingList.ImperialVolumeUnit, packingList.ImperialVolume),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray_1([["className", join(" ", ["column"])], (elems_7 = [createElement("p", {
                children: "Imperial Weight",
            }), createElement("span", {
                className: join(" ", ["is-size-6 has-text-weight-bold"]),
                children: formatDecimalWithUnits(packingList.ImperialWeightUnit, packingList.ImperialWeight),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray_1([["className", join(" ", ["column"])], (elems_8 = [createElement("p", {
                children: "Metric Volume",
            }), createElement("span", {
                className: join(" ", ["is-size-6 has-text-weight-bold"]),
                children: formatDecimalWithUnits(packingList.MetricVolumeUnit, packingList.MetricVolume),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray_1([["className", join(" ", ["column"])], (elems_9 = [createElement("p", {
                children: "Metric Weight",
            }), createElement("span", {
                className: join(" ", ["is-size-6 has-text-weight-bold"]),
                children: formatDecimalWithUnits(packingList.MetricWeightUnit, packingList.MetricWeight),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray_1([["className", join(" ", ["column", "is-flex"])], (elems_11 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_10 = [ModeOfTransportIcon_render(new IconType(0, []), packingList.ModeOfTransportIcon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), (children = ofArray_1([createElement("a", {
                href: RouterModule_encodeParts(ofArray_1(["/shipment-details", (packingList.ShipmentId != null) ? value_315(packingList.ShipmentId) : ""]), 1),
                children: packingList.ShipmentNumber,
            }), createElement("div", {
                children: [packingList.ServiceTypeName],
            }), createElement("div", {
                children: [packingList.ServiceLevelName],
            })]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray_1([["className", "block"], (elems_44 = [createElement("div", createObj(ofArray_1([["className", "columns"], (elems_34 = [createElement("div", createObj(ofArray_1([["className", "column"], (elems_33 = [createElement("nav", createObj(ofArray_1([["className", "level"], (elems_32 = toList(delay(() => {
                let elems_27, elems_14, elems_17, elems_16, value_107, elems_15, elems_20, elems_19, value_129, elems_18, elems_23, elems_22, value_151, elems_21, elems_26, elems_25, value_173, elems_24;
                return append_1(singleton_1(createElement("div", createObj(ofArray_1([["className", "level-left"], (elems_27 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["level-item has-text-weight-semibold"])], (elems_14 = [createElement("p", {
                    children: "Filters",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray_1([["className", "level-item"], (elems_17 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["select is-small is-fullwidth"])], (elems_16 = [createElement("select", createObj(ofArray_1([["className", join(" ", [])], ["id", "PurchaseOrderNumber"], ["name", "PurchaseOrderNumber"], (value_107 = state.SelectedPONumber, ["ref", (e) => {
                    if (!(e == null) && !equals(e.value, value_107)) {
                        e.value = value_107;
                    }
                }]), (elems_15 = toList(delay(() => append_1(singleton_1(createElement("option", {
                    children: "Purchase Order",
                    value: "All",
                })), delay(() => map_1((poNumber) => createElement("option", {
                    children: poNumber,
                    value: poNumber,
                }), state.FilterDataSource.PurchaseOrderNumbers))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))]), ["onChange", (e_1) => {
                    const element = e_1.target;
                    dispatch(new Msg(3, [element.value]));
                    dispatch(new Msg(7, [packingList]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray_1([["className", "level-item"], (elems_20 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["select is-small is-fullwidth"])], (elems_19 = [createElement("select", createObj(ofArray_1([["className", join(" ", [])], ["id", "MaterialReceiptsNumber"], ["name", "MaterialReceiptsNumber"], (value_129 = state.SelectedMRNumber, ["ref", (e_2) => {
                    if (!(e_2 == null) && !equals(e_2.value, value_129)) {
                        e_2.value = value_129;
                    }
                }]), (elems_18 = toList(delay(() => append_1(singleton_1(createElement("option", {
                    value: "All",
                    children: "MR Number",
                })), delay(() => map_1((mrNumber) => createElement("option", {
                    value: mrNumber,
                    children: mrNumber,
                }), state.FilterDataSource.MaterialReceiptNumbers))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))]), ["onChange", (e_3) => {
                    const element_1 = e_3.target;
                    dispatch(new Msg(6, [element_1.value]));
                    dispatch(new Msg(7, [packingList]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray_1([["className", "level-item"], (elems_23 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["select is-small is-fullwidth"])], (elems_22 = [createElement("select", createObj(ofArray_1([["className", join(" ", [])], ["id", "BoxNumber"], ["name", "BoxNumber"], (value_151 = state.SelectedBoxNumber, ["ref", (e_4) => {
                    if (!(e_4 == null) && !equals(e_4.value, value_151)) {
                        e_4.value = value_151;
                    }
                }]), (elems_21 = toList(delay(() => append_1(singleton_1(createElement("option", {
                    value: "All",
                    children: "Box Number",
                })), delay(() => map_1((boxNumber) => createElement("option", {
                    value: boxNumber,
                    children: boxNumber,
                }), state.FilterDataSource.BoxNumbers))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]), ["onChange", (e_5) => {
                    const element_2 = e_5.target;
                    dispatch(new Msg(5, [element_2.value]));
                    dispatch(new Msg(7, [packingList]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("div", createObj(ofArray_1([["className", "level-item"], (elems_26 = [createElement("div", createObj(ofArray_1([["className", join(" ", ["select is-small is-fullwidth"])], (elems_25 = [createElement("select", createObj(ofArray_1([["className", join(" ", [])], ["id", "Vendor"], ["name", "Vendor"], (value_173 = state.SelectedVendor, ["ref", (e_6) => {
                    if (!(e_6 == null) && !equals(e_6.value, value_173)) {
                        e_6.value = value_173;
                    }
                }]), (elems_24 = toList(delay(() => append_1(singleton_1(createElement("option", {
                    value: "All",
                    children: "Vendor",
                })), delay(() => map_1((vendor) => createElement("option", {
                    value: vendor,
                    children: vendor,
                }), state.FilterDataSource.Vendors))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))]), ["onChange", (e_7) => {
                    const element_3 = e_7.target;
                    dispatch(new Msg(4, [element_3.value]));
                    dispatch(new Msg(7, [packingList]));
                }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))), delay(() => {
                    let elems_31, elems_30;
                    return state.User.CanExportSearchResults ? singleton_1(createElement("div", createObj(ofArray_1([["className", "level-right"], (elems_31 = [createElement("p", createObj(ofArray_1([["className", "level-item"], (elems_30 = toList(delay(() => {
                        let elems_29, elems_28;
                        const matchValue_1 = state.FilteredPackingList;
                        switch (matchValue_1.tag) {
                            case 2: {
                                const materialReceiptItems = collect((x_3) => collect((x_4) => x_4.MaterialReceiptItems, x_3.MaterialReceipts), matchValue_1.fields[0]);
                                return singleton_1(createElement("button", createObj(ofArray_1([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", (materialReceiptItems.length === 0) ? true : equals(state.ExportPackingListDetailsDeferred, new Deferred$1(1, []))], ["onClick", (_arg_3) => {
                                    if (materialReceiptItems.length > 0) {
                                        dispatch(new Msg(8, [new AsyncMsg$1(0, [])]));
                                    }
                                }], (elems_29 = [createElement("span", {
                                    className: join(" ", ["material-icons"]),
                                    children: "download",
                                }), createElement("span", {
                                    className: join(" ", ["mx-1"]),
                                }), createElement("span", {
                                    children: (state.ExportPackingListDetailsDeferred.tag === 1) ? "Exporting..." : "Export",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))));
                            }
                            default:
                                return singleton_1(createElement("button", createObj(ofArray_1([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", true], (elems_28 = [createElement("span", {
                                    className: join(" ", ["material-icons"]),
                                    children: "download",
                                }), createElement("span", {
                                    className: join(" ", ["mx-1"]),
                                }), createElement("span", {
                                    children: "Export",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))));
                        }
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))) : empty();
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])]))), createElement("div", createObj(ofArray_1([["className", "box"], (elems_43 = toList(delay(() => {
                let elems_42;
                const matchValue_3 = state.FilteredPackingList;
                switch (matchValue_3.tag) {
                    case 2: {
                        const packingListBox_1 = matchValue_3.fields[0];
                        return singleton_1(createElement("div", createObj(ofArray_1([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                            maxHeight: 450 + "px",
                            overflowY: "auto",
                        }], (elems_42 = toList(delay(() => {
                            let elems_41, elems_36, elems_35, elems_40;
                            const materialReceiptItems_1 = collect((x_5) => collect((x_6) => x_6.MaterialReceiptItems, x_5.MaterialReceipts), packingListBox_1);
                            return (materialReceiptItems_1.length === 0) ? singleton_1(createElement("div", {
                                className: join(" ", ["has-text-centered"]),
                                children: "No material receipt items found",
                            })) : singleton_1(createElement("table", createObj(ofArray_1([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_41 = [createElement("thead", createObj(singleton((elems_36 = [createElement("tr", createObj(ofArray_1([["className", join(" ", [])], (elems_35 = [createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "Purchase Order",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "MR Number",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "Box Number",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "MR Item",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "PO Item  Number",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "Vendor",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "Part Number",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "Description",
                            }), createElement("th", {
                                className: join(" ", ["p-2"]),
                                children: "Quantity Received",
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])))), createElement("tbody", createObj(ofArray_1([["className", join(" ", [])], (elems_40 = toList(delay(() => collect_1((item) => map_1((materialReceiptItem) => {
                                let elems_39, elems_37, elems_38;
                                return createElement("tr", createObj(ofArray_1([["className", join(" ", [])], (elems_39 = [createElement("td", createObj(ofArray_1([["className", join(" ", ["p-2"])], (elems_37 = [createElement("a", {
                                    className: join(" ", ["is-link", "is-underlined"]),
                                    href: RouterModule_encodeParts(ofArray_1(["/purchase-order-details", materialReceiptItem.PurchaseOrderId]), 1),
                                    children: materialReceiptItem.PurchaseOrderNumber,
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])]))), createElement("td", createObj(ofArray_1([["className", join(" ", ["p-2"])], (elems_38 = [createElement("a", {
                                    className: join(" ", ["is-link", "is-underlined"]),
                                    href: RouterModule_encodeParts(ofArray_1(["/material-receipt-details", materialReceiptItem.MaterialReceiptId]), 1),
                                    children: materialReceiptItem.MaterialReceiptNumber,
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: item.BoxNumber,
                                }), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: formatInt(materialReceiptItem.MaterialReceiptItem),
                                }), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: formatInt(materialReceiptItem.PurchaseOrderLineNumber),
                                }), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: materialReceiptItem.VendorName,
                                }), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: materialReceiptItem.PartNumber,
                                }), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: materialReceiptItem.Description,
                                }), createElement("td", {
                                    className: join(" ", ["p-2"]),
                                    children: formatDecimalWithUnits(materialReceiptItem.QuantityReceivedUnit, materialReceiptItem.QuantityReceived),
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])));
                            }, sortBy((x_8) => x_8.MaterialReceiptItem, collect((x_7) => x_7.MaterialReceiptItems, item.MaterialReceipts), {
                                Compare: comparePrimitives,
                            })), packingListBox_1))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])]))));
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])]))));
                    }
                    default:
                        return singleton_1(render_1());
                }
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))]))));
        }
    }
}

