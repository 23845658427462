import { toString, Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, string_type, class_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Booking_$reflection, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTime_formatNullable, LocalStorage_getGlobalAccount, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { GlobalAccountTotalCountResponse_$reflection } from "../../CView.DTOs/Inventory.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { find } from "../Api/BookingApi.fs.js";
import { createElement } from "react";
import { printf, isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, empty, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { formatDecimalWithUnits } from "../Domain/Formatter.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class SelectableTabs extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Details", "SpecialInstructions", "Items", "Documents", "References"];
    }
}

export function SelectableTabs_$reflection() {
    return union_type("CView.UI.Pages.BookingDetails.SelectableTabs", [], SelectableTabs, () => [[], [], [], [], []]);
}

export class State extends Record {
    constructor(BookingId, User, Booking, ActiveTab) {
        super();
        this.BookingId = BookingId;
        this.User = User;
        this.Booking = Booking;
        this.ActiveTab = ActiveTab;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.BookingDetails.State", [], State, () => [["BookingId", class_type("System.Guid")], ["User", User_$reflection()], ["Booking", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Booking_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Booking_$reflection()]], [["ErrorValue", string_type]]]))], ["ActiveTab", SelectableTabs_$reflection()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "AccountHierarchyItem"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.BookingDetails.Intent", [], Intent, () => [[], [["Item", GlobalAccountTotalCountResponse_$reflection()]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetActiveTab", "GetBooking"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.BookingDetails.Msg", [], Msg, () => [[["Item", SelectableTabs_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Booking_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Booking_$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

export function init(bookingId, user) {
    return [new State(bookingId, user, new Deferred$1(0, []), new SelectableTabs(0, [])), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    })))];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            let patternInput;
            if (msg.fields[0].fields[0].tag === 1) {
                patternInput = [Alert_error(Alert_title("Get Booking", Alert_text(msg.fields[0].fields[0].fields[0]))), new Intent(0, [])];
            }
            else {
                const hasGlobalAccount = LocalStorage_getGlobalAccount() != null;
                patternInput = [Cmd_none(), hasGlobalAccount ? (new Intent(0, [])) : (new Intent(1, [msg.fields[0].fields[0].fields[0].GlobalAccountTotalCount]))];
            }
            return [new State(state.BookingId, state.User, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ActiveTab), patternInput[0], patternInput[1]];
        }
        else if (equals(state.Booking, new Deferred$1(1, []))) {
            return [state, Cmd_none(), new Intent(0, [])];
        }
        else {
            return [new State(state.BookingId, state.User, new Deferred$1(1, []), state.ActiveTab), Cmd_OfAsyncWith_perform((computation) => {
                startImmediate(computation);
            }, find, state.BookingId, (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
        }
    }
    else {
        return [new State(state.BookingId, state.User, state.Booking, msg.fields[0]), Cmd_none(), new Intent(0, [])];
    }
}

export function render(state, dispatch) {
    let elems_53;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_53 = toList(delay(() => {
        let elems_2, elems_1, children, elems;
        const matchValue_1 = state.Booking;
        return (matchValue_1.tag === 1) ? singleton_1(render_1()) : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? append(singleton_1(createElement("nav", createObj(ofArray([["aria-label", "breadcrumbs"], ["className", join(" ", ["breadcrumb", "is-small", "box", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between", "is-align-items-center"])], (elems_2 = [createElement("ul", createObj(ofArray([["className", "has-text-weight-semibold"], (elems_1 = [(children = singleton(createElement("a", {
            className: "is-size-6",
            href: RouterModule_encodeParts(singleton("/bookings"), 1),
            children: "Booking",
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("li", createObj(ofArray([["className", join(" ", ["is-active", "is-size-6"])], (elems = [createElement("a", {
            children: matchValue_1.fields[0].fields[0].BookingNumber,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let booking_4, elems_52, elems_51, booking_1, elems_29, elems_11, elems_4, elems_3, elems_6, elems_5, elems_8, elems_7, elems_10, elems_9, elems_20, elems_13, elems_12, elems_15, elems_14, elems_17, elems_16, elems_19, elems_18, elems_28, elems_22, elems_21, elems_27, elems_26, elems_25, elems_50, elems_48, children_2, elems_43, elems_44, fmt, elems_45, fmt_1, elems_46, fmt_2, elems_47, fmt_3, elems_49;
            return singleton_1((booking_4 = matchValue_1.fields[0].fields[0], createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_52 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_51 = [(booking_1 = booking_4, createElement("div", createObj(ofArray([["className", "box"], (elems_29 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_11 = [createElement("div", createObj(ofArray([["className", "column"], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_3 = [ModeOfTransportIcon_render(new IconType(0, []), booking_1.ModeOfTransportIcon), createElement("h1", {
                className: join(" ", ["is-size-6", "has-text-weight-semibold"]),
                children: booking_1.BookingStatusDescription,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_5 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Booking Date",
            }), createElement("span", {
                children: [DateTime_formatNullable(booking_1.BookingDate)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_7 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Estimated Pickup Date",
            }), createElement("span", {
                children: [DateTime_formatNullable(booking_1.EstimatedPickupDate)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_9 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Estimated Delivery Date",
            }), createElement("span", {
                children: [DateTime_formatNullable(booking_1.EstimatedDeliveryDate)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_20 = [createElement("div", createObj(ofArray([["className", "column"], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_12 = toList(delay(() => append(singleton_1(createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Total Pieces",
            })), delay(() => (!isNullOrWhiteSpace(booking_1.TotalPieces) ? singleton_1(createElement("span", {
                children: [(booking_1.TotalPieces + " ") + booking_1.PiecesUnit],
            })) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_14 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Total Volume Imperial",
            }), createElement("span", {
                children: [formatDecimalWithUnits(booking_1.TotalVolumeImperialUnit, booking_1.TotalVolumeImperial)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_16 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Total Volume Metric",
            }), createElement("span", {
                children: [formatDecimalWithUnits(booking_1.TotalVolumeMetricUnit, booking_1.TotalVolumeMetric)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_18 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Total Weight Imperial",
            }), createElement("span", {
                children: [formatDecimalWithUnits(booking_1.TotalWeightImperialUnit, booking_1.TotalWeightImperial)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_28 = [createElement("div", createObj(ofArray([["className", "column"], (elems_22 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_21 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Total Weight Metric",
            }), createElement("span", {
                children: [formatDecimalWithUnits(booking_1.TotalWeightMetricUnit, booking_1.TotalWeightMetric)],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])]))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray([]),
            }), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray([]),
            }), createElement("div", createObj(ofArray([["className", "column"], (elems_27 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap", "is-align-content-center", "is-align-items-center"])], (elems_26 = [ModeOfTransportIcon_render(new IconType(0, []), booking_1.ShipmentModeOfTransportIcon), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_25 = toList(delay(() => {
                const shipmentId = booking_1.ShipmentId;
                return append((shipmentId != null) ? singleton_1(createElement("a", {
                    className: join(" ", ["is-size-6"]),
                    href: RouterModule_encodeParts(ofArray(["/shipment-details", toString(shipmentId)]), 1),
                    children: booking_1.ShipmentNumber,
                })) : empty(), delay(() => append(singleton_1(createElement("div", {
                    children: [booking_1.ServiceTypeName],
                })), delay(() => singleton_1(createElement("div", {
                    children: [booking_1.Service],
                }))))));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_50 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-boxed"])], (elems_48 = [(children_2 = ofArray([createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton_1("is-active") : empty()))))))))], (elems_43 = [createElement("a", {
                onClick: (_arg) => {
                    dispatch(new Msg(0, [new SelectableTabs(0, [])]));
                },
                children: "Details",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(1, [])) ? singleton_1("is-active") : empty()))))))))], (elems_44 = [createElement("a", createObj(ofArray([["onClick", (_arg_1) => {
                dispatch(new Msg(0, [new SelectableTabs(1, [])]));
            }], (fmt = printf("Special Instructions"), fmt.cont((value_207) => ["children", value_207]))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(2, [])) ? singleton_1("is-active") : empty()))))))))], (elems_45 = [createElement("a", createObj(ofArray([["onClick", (_arg_2) => {
                dispatch(new Msg(0, [new SelectableTabs(2, [])]));
            }], (fmt_1 = printf("Items"), fmt_1.cont((value_213) => ["children", value_213]))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(3, [])) ? singleton_1("is-active") : empty()))))))))], (elems_46 = [createElement("a", createObj(ofArray([["onClick", (_arg_3) => {
                dispatch(new Msg(0, [new SelectableTabs(3, [])]));
            }], (fmt_2 = printf("Documents"), fmt_2.cont((value_219) => ["children", value_219]))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])]))), createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(4, [])) ? singleton_1("is-active") : empty()))))))))], (elems_47 = [createElement("a", createObj(ofArray([["onClick", (_arg_4) => {
                dispatch(new Msg(0, [new SelectableTabs(4, [])]));
            }], (fmt_3 = printf("References"), fmt_3.cont((value_225) => ["children", value_225]))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])])))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])]))), createElement("div", createObj(ofArray([["className", "tabcontent"], (elems_49 = toList(delay(() => {
                let booking_2, elems_42, elems_36, elems_31, elems_30, elems_33, elems_32, elems_35, elems_34, elems_41, elems_38, elems_37, elems_40, elems_39;
                const matchValue = state.ActiveTab;
                return (matchValue.tag === 1) ? singleton_1(defaultOf()) : ((matchValue.tag === 2) ? singleton_1(defaultOf()) : ((matchValue.tag === 3) ? singleton_1(defaultOf()) : ((matchValue.tag === 4) ? singleton_1(defaultOf()) : singleton_1((booking_2 = booking_4, createElement("div", createObj(ofArray([["className", join(" ", ["tab", "mx-4"])], ["id", "details"], ["style", {
                    minHeight: 300 + "px",
                }], (elems_42 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_36 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems_31 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_30 = toList(delay(() => append(singleton_1(createElement("span", {
                    className: "has-text-weight-semibold",
                    children: "Client",
                })), delay(() => append(singleton_1(createElement("span", {
                    children: [booking_2.CustomerName],
                })), delay(() => map((address) => createElement("span", {
                    children: [address],
                }), booking_2.CustomerAddress))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_33 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_32 = toList(delay(() => append(singleton_1(createElement("span", {
                    className: "has-text-weight-semibold",
                    children: "Shipper",
                })), delay(() => append(singleton_1(createElement("span", {
                    children: [booking_2.ShipperName],
                })), delay(() => map((address_1) => createElement("span", {
                    children: [address_1],
                }), booking_2.ShipperAddress))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_35 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_34 = toList(delay(() => append(singleton_1(createElement("span", {
                    className: "has-text-weight-semibold",
                    children: "Consignee",
                })), delay(() => append(singleton_1(createElement("span", {
                    children: [booking_2.ConsigneeName],
                })), delay(() => map((address_2) => createElement("span", {
                    children: [address_2],
                }), booking_2.ConsigneeAddress))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_41 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems_38 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_37 = [createElement("span", {
                    className: "has-text-weight-semibold",
                    children: "Ship To",
                }), createElement("span", {
                    children: [booking_2.Destination],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems_40 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "is-justify-content-space-between"])], (elems_39 = [createElement("span", {
                    className: "has-text-weight-semibold",
                    children: "Ship From",
                }), createElement("span", {
                    children: [booking_2.PlaceOfReceipt],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])]))), createElement("div", {
                    className: join(" ", ["column", "is-one-third"]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])]))))))));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_51))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_52))])])))));
        })) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_53))])])));
}

