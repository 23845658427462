import { Record } from "./fable_modules/fable-library.4.10.0/Types.js";
import { option_type, string_type, record_type, array_type, class_type, bool_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";

export class DataSource extends Record {
    constructor(IsPaginated, Data) {
        super();
        this.IsPaginated = IsPaginated;
        this.Data = Data;
    }
}

export function DataSource_$reflection() {
    return record_type("CView.UI.Models.DataSource", [], DataSource, () => [["IsPaginated", bool_type], ["Data", array_type(class_type("CView.UI.Models.IDataSourceItem"))]]);
}

export class SelectableItem extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function SelectableItem_$reflection() {
    return record_type("CView.UI.Models.SelectableItem", [], SelectableItem, () => [["Id", string_type], ["Name", string_type]]);
}

export class ShipmentPerformanceQuery extends Record {
    constructor(ShipmentStatus, ModeOfTransport, ShipmentDate, StartDate, EndDate, PortOfLadingCityCountryCode, PortOfLadingLocation, PortOfDischargeCityCountryCode, PortOfDischargeLocation) {
        super();
        this.ShipmentStatus = ShipmentStatus;
        this.ModeOfTransport = ModeOfTransport;
        this.ShipmentDate = ShipmentDate;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.PortOfLadingCityCountryCode = PortOfLadingCityCountryCode;
        this.PortOfLadingLocation = PortOfLadingLocation;
        this.PortOfDischargeCityCountryCode = PortOfDischargeCityCountryCode;
        this.PortOfDischargeLocation = PortOfDischargeLocation;
    }
}

export function ShipmentPerformanceQuery_$reflection() {
    return record_type("CView.UI.Models.ShipmentPerformanceQuery", [], ShipmentPerformanceQuery, () => [["ShipmentStatus", string_type], ["ModeOfTransport", string_type], ["ShipmentDate", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["PortOfLadingCityCountryCode", string_type], ["PortOfLadingLocation", string_type], ["PortOfDischargeCityCountryCode", string_type], ["PortOfDischargeLocation", string_type]]);
}

export class ShipmentInTransitQuery extends Record {
    constructor(ShipmentStatus, ModeOfTransport, HasDelays, HasPotentialDelays, EstimatedDeliveryDate, DestinationLocation, DestinationCityCountryCode) {
        super();
        this.ShipmentStatus = ShipmentStatus;
        this.ModeOfTransport = ModeOfTransport;
        this.HasDelays = HasDelays;
        this.HasPotentialDelays = HasPotentialDelays;
        this.EstimatedDeliveryDate = EstimatedDeliveryDate;
        this.DestinationLocation = DestinationLocation;
        this.DestinationCityCountryCode = DestinationCityCountryCode;
    }
}

export function ShipmentInTransitQuery_$reflection() {
    return record_type("CView.UI.Models.ShipmentInTransitQuery", [], ShipmentInTransitQuery, () => [["ShipmentStatus", string_type], ["ModeOfTransport", string_type], ["HasDelays", bool_type], ["HasPotentialDelays", bool_type], ["EstimatedDeliveryDate", option_type(class_type("System.DateTime"))], ["DestinationLocation", option_type(string_type)], ["DestinationCityCountryCode", option_type(string_type)]]);
}

export class BillingIntentQuery extends Record {
    constructor(Status, SelectedInvoiceDate, StartDate, EndDate) {
        super();
        this.Status = Status;
        this.SelectedInvoiceDate = SelectedInvoiceDate;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
    }
}

export function BillingIntentQuery_$reflection() {
    return record_type("CView.UI.Models.BillingIntentQuery", [], BillingIntentQuery, () => [["Status", string_type], ["SelectedInvoiceDate", string_type], ["StartDate", option_type(class_type("System.DateTime"))], ["EndDate", option_type(class_type("System.DateTime"))]]);
}

