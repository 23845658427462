import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { class_type, record_type, union_type, string_type, unit_type, array_type, bool_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { NewFavoritedShipment, ShipmentSearchItem, ShipmentSearchItem_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTimeOffset_formatNullable, System_String__String_get_NonEmpty, DateTime_formatNullable, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "./SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { createFavouriteShipment as createFavouriteShipment_1, deleteFavouriteShipment as deleteFavouriteShipment_1 } from "../Api/UserApi.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { createElement } from "react";
import { empty, singleton, append, map as map_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { toText, printf, compare, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { IconType, ModeOfTransportIcon_render } from "./ModeOfTransportIcon.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatDecimal, formatDecimalWithUnits, formatInt, formatIntWithUnits } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(IsEditable, IsPinHidden, CanViewCO2Emissions, Shipments, ShipmentPinnedResponse, ShipmentUnpinnedResponse) {
        super();
        this.IsEditable = IsEditable;
        this.IsPinHidden = IsPinHidden;
        this.CanViewCO2Emissions = CanViewCO2Emissions;
        this.Shipments = Shipments;
        this.ShipmentPinnedResponse = ShipmentPinnedResponse;
        this.ShipmentUnpinnedResponse = ShipmentUnpinnedResponse;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.ShipmentInfo.ShipmentInfo.State", [], State, () => [["IsEditable", bool_type], ["IsPinHidden", bool_type], ["CanViewCO2Emissions", bool_type], ["Shipments", array_type(ShipmentSearchItem_$reflection())], ["ShipmentPinnedResponse", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["ShipmentUnpinnedResponse", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "ShipmentPinned", "ShipmentUnpinned"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.ShipmentInfo.ShipmentInfo.Intent", [], Intent, () => [[], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item", class_type("System.Guid")]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PinShipment", "UnpinShipment"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.ShipmentInfo.ShipmentInfo.Msg", [], Msg, () => [[["Item1", class_type("System.Guid")], ["Item2", string_type], ["Item3", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Guid")], ["Item2", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init() {
    return [new State(false, false, false, new Array(0), new Deferred$1(0, []), new Deferred$1(0, [])), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            return [(msg.fields[1].fields[0].tag === 0) ? (new State(state.IsEditable, state.IsPinHidden, state.CanViewCO2Emissions, map((shipment_1) => {
                if (shipment_1.Id === msg.fields[0]) {
                    return new ShipmentSearchItem(shipment_1.Id, shipment_1.Customer, shipment_1.Shipper, shipment_1.Consignee, shipment_1.BookingNumber, shipment_1.ShipmentNumber, shipment_1.ShipmentStatus, shipment_1.CurrentLocation, shipment_1.PlaceOfReceipt, shipment_1.PortOfLading, shipment_1.PortOfDischarge, shipment_1.FinalDestination, shipment_1.BookingDate, shipment_1.StartDate, shipment_1.DueDate, shipment_1.DeliveredDate, shipment_1.EstimatedDeliveryDate, shipment_1.ServiceType, shipment_1.Service, shipment_1.TotalChargeableWeight, shipment_1.TotalChargeableWeightUnit, shipment_1.TotalPieces, shipment_1.TotalPiecesUnit, shipment_1.TotalEmissions, shipment_1.ModeOfTransport, shipment_1.ModeOfTransportIcon, shipment_1.HasDelays, shipment_1.HasPotentialDelays, false, shipment_1.LatestCompletedMilestoneName, shipment_1.HasVisibleSteps, shipment_1.ContainerCount);
                }
                else {
                    return shipment_1;
                }
            }, state.Shipments), state.ShipmentPinnedResponse, new Deferred$1(2, [new FSharpResult$2(0, [void 0])]))) : state, (msg.fields[1].fields[0].tag === 1) ? Alert_error(Alert_title("Unpin Shipment", Alert_text(msg.fields[1].fields[0].fields[0]))) : Toast_success(Toast_title("Unpin Shipment", Toast_text("You have successfully unpinned the shipment from the dashboard"))), (msg.fields[1].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(2, [msg.fields[0]]))];
        }
        else if (equals(state.ShipmentUnpinnedResponse, new Deferred$1(1, []))) {
            return [state, Cmd_none(), new Intent(0, [])];
        }
        else {
            return [state, Cmd_OfAsyncWith_perform((computation_1) => {
                startImmediate(computation_1);
            }, () => deleteFavouriteShipment_1(msg.fields[0]), void 0, (arg_2) => (new Msg(1, [msg.fields[0], new AsyncMsg$1(1, [arg_2])]))), new Intent(0, [])];
        }
    }
    else if (msg.fields[2].tag === 1) {
        return [(msg.fields[2].fields[0].tag === 1) ? state : (new State(state.IsEditable, state.IsPinHidden, state.CanViewCO2Emissions, map((shipment) => {
            if (shipment.Id === msg.fields[0]) {
                return new ShipmentSearchItem(shipment.Id, shipment.Customer, shipment.Shipper, shipment.Consignee, shipment.BookingNumber, shipment.ShipmentNumber, shipment.ShipmentStatus, shipment.CurrentLocation, shipment.PlaceOfReceipt, shipment.PortOfLading, shipment.PortOfDischarge, shipment.FinalDestination, shipment.BookingDate, shipment.StartDate, shipment.DueDate, shipment.DeliveredDate, shipment.EstimatedDeliveryDate, shipment.ServiceType, shipment.Service, shipment.TotalChargeableWeight, shipment.TotalChargeableWeightUnit, shipment.TotalPieces, shipment.TotalPiecesUnit, shipment.TotalEmissions, shipment.ModeOfTransport, shipment.ModeOfTransportIcon, shipment.HasDelays, shipment.HasPotentialDelays, true, shipment.LatestCompletedMilestoneName, shipment.HasVisibleSteps, shipment.ContainerCount);
            }
            else {
                return shipment;
            }
        }, state.Shipments), new Deferred$1(2, [new FSharpResult$2(0, [void 0])]), state.ShipmentUnpinnedResponse)), (msg.fields[2].fields[0].tag === 1) ? Alert_error(Alert_title("Pin Shipment", Alert_text(msg.fields[2].fields[0].fields[0]))) : Toast_success(Toast_title("Pin Shipment", Toast_text("You have successfully pinned the shipment"))), (msg.fields[2].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(1, [msg.fields[0], msg.fields[1]]))];
    }
    else if (equals(state.ShipmentPinnedResponse, new Deferred$1(1, []))) {
        return [state, Cmd_none(), new Intent(0, [])];
    }
    else {
        return [new State(state.IsEditable, state.IsPinHidden, state.CanViewCO2Emissions, state.Shipments, new Deferred$1(1, []), state.ShipmentUnpinnedResponse), Cmd_OfAsyncWith_perform((computation) => {
            startImmediate(computation);
        }, () => createFavouriteShipment_1(new NewFavoritedShipment(msg.fields[0])), void 0, (arg) => (new Msg(0, [msg.fields[0], msg.fields[1], new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
    }
}

export function render(state, dispatch) {
    let elems_33;
    if (state.Shipments.length === 0) {
        return defaultOf();
    }
    else {
        return createElement("div", createObj(ofArray([["className", "box"], (elems_33 = toList(delay(() => map_1((shipment) => {
            const children_2 = toList(delay(() => {
                let elems_2, elems;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-row", "is-justify-content-space-between", "is-flex-wrap-wrap", "my-4"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems = [ModeOfTransportIcon_render(new IconType(0, []), shipment.ModeOfTransportIcon), createElement("a", {
                    className: join(" ", ["is-size-6"]),
                    href: RouterModule_encodeParts(ofArray(["/shipment-details", shipment.Id]), 1),
                    children: shipment.ShipmentNumber,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(toList(delay(() => append(state.IsEditable ? singleton(["title", shipment.IsPinned ? "Unpin" : "Pin"]) : empty(), delay(() => append(singleton(["className", join(" ", ["is-flex", "is-align-content-center"])]), delay(() => {
                    let elems_1;
                    return singleton((elems_1 = toList(delay(() => append(shipment.HasDelays ? singleton(createElement("span", {
                        className: join(" ", ["tag", "is-danger", "mr-1"]),
                        children: "Delayed",
                    })) : empty(), delay(() => append(shipment.HasPotentialDelays ? singleton(createElement("span", {
                        className: join(" ", ["tag", "is-warning", "ml-1"]),
                        children: "Potential Delay",
                    })) : empty(), delay(() => (!state.IsPinHidden ? singleton(createElement("i", {
                        className: join(" ", toList(delay(() => append(singleton("material-icons"), delay(() => append(singleton("mot-icon"), delay(() => append(singleton("m1-3"), delay(() => append(state.IsEditable ? singleton("is-clickable") : empty(), delay(() => (!shipment.IsPinned ? singleton("has-text-grey-light") : empty())))))))))))),
                        children: "push_pin",
                        onClick: (_arg) => {
                            if (!state.IsEditable) {
                            }
                            else if (shipment.IsPinned) {
                                dispatch(new Msg(1, [shipment.Id, new AsyncMsg$1(0, [])]));
                            }
                            else {
                                dispatch(new Msg(0, [shipment.Id, shipment.ShipmentNumber, new AsyncMsg$1(0, [])]));
                            }
                        },
                    })) : empty()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
                }))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_32, elems_7, elems_6, elems_31, elems_30, elems_29, elems_28;
                    return append(singleton(createElement("div", createObj(ofArray([["className", "columns"], ["style", {
                        overflowX: "auto",
                    }], (elems_32 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column", "p-2"])], (elems_6 = toList(delay(() => append(singleton(createElement("span", {
                        className: join(" ", ["has-text-weight-semibold"]),
                        children: shipment.LatestCompletedMilestoneName,
                    })), delay(() => append(singleton(createElement("div", {
                        children: [shipment.ServiceType],
                    })), delay(() => append(singleton(createElement("div", {
                        children: [shipment.Service],
                    })), delay(() => {
                        let children;
                        return append(singleton((children = ofArray([createElement("span", {
                            className: join(" ", ["has-text-weight-semibold", "mr-2"]),
                            children: "Due Date",
                        }), createElement("span", {
                            children: [DateTime_formatNullable(shipment.DueDate)],
                        })]), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))), delay(() => {
                            let elems_3;
                            return append(singleton(createElement("div", createObj(ofArray([["className", "is-flex"], (elems_3 = toList(delay(() => append(singleton(createElement("span", {
                                className: "has-text-weight-semibold",
                                children: formatIntWithUnits(shipment.TotalPiecesUnit, shipment.TotalPieces),
                            })), delay(() => append((compare(shipment.ModeOfTransport, "Ocean", 5) === 0) ? append(singleton(createElement("span", {
                                className: join(" ", ["has-text-weight-semibold", "mx-2"]),
                                children: " | ",
                            })), delay(() => {
                                let fmt;
                                return singleton(createElement("span", createObj(ofArray([["className", "has-text-weight-semibold"], ((fmt = printf("%s %s"), fmt.cont((value_54) => ["children", value_54])))(formatInt(shipment.ContainerCount))((shipment.ContainerCount === 1) ? "Container" : "Containers")]))));
                            })) : empty(), delay(() => ((shipment.TotalChargeableWeight != null) ? append(singleton(createElement("span", {
                                className: join(" ", ["has-text-weight-semibold", "mx-2"]),
                                children: " | ",
                            })), delay(() => singleton(createElement("span", {
                                className: "has-text-weight-semibold",
                                children: formatDecimalWithUnits(shipment.TotalChargeableWeightUnit, shipment.TotalChargeableWeight),
                            })))) : empty()))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                                let elems_5, elems_4, arg;
                                return (state.CanViewCO2Emissions && (shipment.TotalEmissions != null)) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "has-text-weight-semibold", "has-text-success", "mt-1"])], (elems_5 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_4 = [createElement("span", {
                                    className: "material-icons",
                                    children: "eco",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                                    children: [(arg = formatDecimal(shipment.TotalEmissions), toText(printf("Total Emissions: %s kg CO2e"))(arg))],
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))) : empty();
                            }));
                        }));
                    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_31 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_30 = [createElement("div", createObj(ofArray([["className", "column"], (elems_29 = [createElement("ul", createObj(ofArray([["className", join(" ", ["steps", "has-content-centered", "is-small", "mb-2"])], (elems_28 = toList(delay(() => append(!shipment.HasVisibleSteps ? singleton(createElement("li", {
                        className: join(" ", ["steps-segment", "is-active", "is-hidden"]),
                    })) : empty(), delay(() => {
                        let elems_12, elems_9, elems_8, elems_11, elems_10;
                        return append(shipment.PlaceOfReceipt.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(singleton(shipment.PlaceOfReceipt.StepClass), delay(() => (!(shipment.PortOfLading.DelayReason == null) ? singleton("is-danger") : empty()))))))))], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.PlaceOfReceipt.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_9 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_8 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.PlaceOfReceipt.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_10 = toList(delay(() => append(singleton(createElement("label", {
                            className: "has-text-weight-semibold",
                            children: "Place Of Receipt",
                        })), delay(() => append(singleton(createElement("div", {
                            children: [shipment.PlaceOfReceipt.Location],
                        })), delay(() => {
                            let arg_1;
                            return append(singleton(createElement("div", {
                                children: (shipment.PlaceOfReceipt.Date != null) ? (shipment.PlaceOfReceipt.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.PlaceOfReceipt.Date) : ((arg_1 = DateTimeOffset_formatNullable(shipment.PlaceOfReceipt.Date), toText(printf("%s %s"))(arg_1)("(ESTIMATED)")))) : "",
                            })), delay(() => (System_String__String_get_NonEmpty(shipment.PlaceOfReceipt.DelayReason) ? singleton(createElement("div", {
                                className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                children: shipment.PlaceOfReceipt.DelayReason,
                            })) : empty())));
                        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))) : empty(), delay(() => {
                            let elems_17, elems_14, elems_13, elems_16, elems_15;
                            return append(shipment.PortOfLading.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(singleton(shipment.PortOfLading.StepClass), delay(() => (!(shipment.PortOfDischarge.DelayReason == null) ? singleton("is-danger") : empty()))))))))], (elems_17 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfLading.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_14 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_13 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.PortOfLading.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_15 = toList(delay(() => append(singleton(createElement("label", {
                                className: "has-text-weight-semibold",
                                children: "Port Of Lading",
                            })), delay(() => append(singleton(createElement("div", {
                                children: [shipment.PortOfLading.Location],
                            })), delay(() => {
                                let arg_3;
                                return append(singleton(createElement("div", {
                                    children: (shipment.PortOfLading.Date != null) ? (shipment.PortOfLading.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.PortOfLading.Date) : ((arg_3 = DateTimeOffset_formatNullable(shipment.PortOfLading.Date), toText(printf("%s %s"))(arg_3)("(ESTIMATED)")))) : "",
                                })), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfLading.DelayReason) ? singleton(createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                    children: shipment.PortOfLading.DelayReason,
                                })) : empty())));
                            })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))) : empty(), delay(() => {
                                let elems_22, elems_19, elems_18, elems_21, elems_20;
                                return append(shipment.PortOfDischarge.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-segment"), delay(() => append(singleton(shipment.PortOfDischarge.StepClass), delay(() => (!(shipment.FinalDestination.DelayReason == null) ? singleton("is-danger") : empty()))))))))], (elems_22 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfDischarge.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_19 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_18 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.PortOfDischarge.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_21 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_20 = toList(delay(() => append(singleton(createElement("label", {
                                    className: "has-text-weight-semibold",
                                    children: "Port Of Discharge",
                                })), delay(() => append(singleton(createElement("div", {
                                    children: [shipment.PortOfDischarge.Location],
                                })), delay(() => {
                                    let arg_5;
                                    return append(singleton(createElement("div", {
                                        children: (shipment.PortOfDischarge.Date != null) ? (shipment.PortOfDischarge.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.PortOfDischarge.Date) : ((arg_5 = DateTimeOffset_formatNullable(shipment.PortOfDischarge.Date), toText(printf("%s %s"))(arg_5)("(ESTIMATED)")))) : "",
                                    })), delay(() => (System_String__String_get_NonEmpty(shipment.PortOfDischarge.DelayReason) ? singleton(createElement("div", {
                                        className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                        children: shipment.PortOfDischarge.DelayReason,
                                    })) : empty())));
                                })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))) : empty(), delay(() => {
                                    let elems_27, elems_24, elems_23, elems_26, elems_25;
                                    return shipment.FinalDestination.IsVisible ? singleton(createElement("li", createObj(ofArray([["className", join(" ", ["steps-segment", shipment.FinalDestination.StepClass])], (elems_27 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("steps-marker"), delay(() => (System_String__String_get_NonEmpty(shipment.FinalDestination.DelayReason) ? singleton("is-danger") : empty()))))))], (elems_24 = [createElement("span", createObj(ofArray([["className", "icon"], (elems_23 = [ModeOfTransportIcon_render(new IconType(1, []), shipment.FinalDestination.Icon)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_26 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_25 = toList(delay(() => append(singleton(createElement("label", {
                                        className: "has-text-weight-semibold",
                                        children: "Final Destination",
                                    })), delay(() => append(singleton(createElement("div", {
                                        children: [shipment.FinalDestination.Location],
                                    })), delay(() => {
                                        let arg_7;
                                        return append(singleton(createElement("div", {
                                            children: (shipment.FinalDestination.Date != null) ? (shipment.FinalDestination.IsActualDateAvailable ? DateTimeOffset_formatNullable(shipment.FinalDestination.Date) : ((arg_7 = DateTimeOffset_formatNullable(shipment.FinalDestination.Date), toText(printf("%s %s"))(arg_7)("(ESTIMATED)")))) : "",
                                        })), delay(() => (System_String__String_get_NonEmpty(shipment.FinalDestination.DelayReason) ? singleton(createElement("div", {
                                            className: join(" ", ["has-text-weight-semibold", "has-text-danger"]),
                                            children: shipment.FinalDestination.DelayReason,
                                        })) : empty())));
                                    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))) : empty();
                                }));
                            }));
                        }));
                    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))), delay(() => ((state.Shipments.length > 1) ? singleton(createElement("hr", {})) : empty())));
                }));
            }));
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            });
        }, state.Shipments))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
    }
}

