import * as pbi from 'powerbi-client';

const powerBiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
let reportMap = new Map();


/**
 * @param {String} reportElementId
 * @param {String} reportId
 * @param {Boolean} isGlobal
 * @param {String} accessToken
 * @param {String[]} accountExternalIds
 * @param {String} globalAccountExternalId
 * @param {String} pageName
 */
export function render(reportElementId, reportId, isGlobal, accessToken, accountExternalIds, globalAccountExternalId, pageName)
{
    try {
        let reportFilters = []
        
        if(isGlobal)
        {
            reportFilters.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "CLIENTS",
                    column: "GLOBAL_ACCOUNT_ID"
                },
                operator: "In",
                values: [globalAccountExternalId],
                displaySettings: {
                    isLockedInViewMode: true,
                    isHiddenInViewMode: true,
                }
            });
        }
        else{
            reportFilters.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "CLIENTS",
                    column: "ACCOUNT_ID"
                },
                operator: "In",
                values: accountExternalIds,
                displaySettings: {
                    isLockedInViewMode: true,
                    isHiddenInViewMode: true
                }
            });
        }
        
        let report = null;

        // Update the existing report if it has already been rendered
        if (reportMap.has(reportElementId)) {

            report = reportMap.get(reportElementId);

            report.setFilters(reportFilters);

            report.getPages().then(function (pages) {
                const page = pages.find(p => p.displayName === pageName);
                if (page) {
                    report.setPage(page.name);

                    report.on('rendered', function () {
                        report.iframe.hidden = false;
                    });
                }
            });
        } else {
            // Render a new Power BI Component
            const container = document.getElementById(reportElementId);

            report = powerBiService.embed(container, {
                type: 'report',
                tokenType: pbi.models.TokenType.Embed,
                accessToken: accessToken,
                embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}`,
                id: reportId,
                settings: {
                    filterPaneEnabled: true,
                    navContentPaneEnabled: false,
                    panes: {
                        filters: {
                            expanded: false,
                            visible: true
                        }
                    }
                }
            });

            report.on('loaded', function (event) {
                report.setFilters(reportFilters);

                report.getPages().then(function (pages) {
                    const page = pages.find(p => p.displayName === pageName);
                    if (page) {
                        report.setPage(page.name);
                    }
                });
            });

            reportMap.set(reportElementId, report);
        }
    }
    catch (error) {
       
    }
}

export function hideReports ()
{
    resetAllReports()
}

function resetAllReports() {
    for (let [key, value] of reportMap.entries()) {
        try {

            // Remove all embedded reports
            powerBiService.reset(document.getElementById(key));

            // Clear the reportInstances object
            reportMap = {};
        }
        catch (error) {
           
        }
    }

    reportMap = new Map()
}